/* eslint-disable */
import LazyLoad from "vanilla-lazyload";
import inView from "in-view";
import Flickity from "flickity";
import VanillaTilt from "vanilla-tilt";
import "altcha";
import { Loader } from "@googlemaps/js-api-loader"

const HTML = document.querySelector("html");

class App {
  constructor() {
    this.lazyload = new LazyLoad({
      unobserve_entered: true,
      elements_selector: ".js-lazy",
      threshold: window.innerHeight * 0.8,
      // eslint-disable-next-line camelcase
      callback_enter: ($el) => {
        if ($el.classList.contains("js-lazy-error-placeholder")) {
          $el.lazyLoadPlaceholder = $el.src;
        } else if ($el.dataset.src == undefined) {
          const figure = $el.closest("figure");
          if (figure) figure.classList.add("img-is-loaded");
        }
      },
      callback_loaded: (el) => {
        const figure = el.closest("figure");
        if (figure) figure.classList.add("img-is-loaded");
      },
      // eslint-disable-next-line camelcase
      callback_error: ($el) => {
        if ($el.lazyLoadPlaceholder) {
          $el.src = $el.lazyLoadPlaceholder;
        }
      },
    });

    this.carousel();
    this.tilt();
    this.textareaResize();
    this.marqueeTxt();
    this.contact();
    this.inViewport();
    this.maps();
  }

  maps() {
    const el = document.querySelector('.maps');

    if( el ) {
      const loader = new Loader({
        apiKey: "AIzaSyBXxZpi8QPItYwx-Ipyu-2dlxn7LYV9kf4",
        version: "weekly",
        // ...additionalOptions,
      });
      
      loader.load().then(async () => {
        const { Map } = await google.maps.importLibrary("maps");
      
        const map = new Map(el, {
          center: { lat: 48.605320, lng: 7.740750 },
          zoom: 16,
          fullscreenControl : false,
          mapTypeControl: false,
          styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
        });


        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(48.605320, 7.740750),
          map: map,
          title: 'Nouvelle Cuisine - 24 rue Saint Charles 67300 Schiltigheim',
          icon: '/static/img/marker.png',
      });
      });
    }
  }

  carousel() {
    const elts = document.querySelectorAll(".carousel");

    elts.forEach((el) => {
      const flkty = new Flickity(el, {
        wrapAround: true,
        cellAlign: "center",
        pageDots: false,
      });
    });
  }

  tilt() {
    const elts = document.querySelectorAll(".tilt");

    if (elts) {
      elts.forEach((el) => {
        VanillaTilt.init(el, {
          reverse: true,
          max: 18,
          speed: 4000,
          "mouse-event-element": ".info",
        });
      });
    }
  }

  marqueeTxt() {
    const elts = document.querySelectorAll(".marquee-txt");
    const speed = 0.2;
    if (elts) {
      elts.forEach((el) => {
        const clone = el.innerHTML;
        const firstElement = el.children[0];
        let i = 0;
        el.insertAdjacentHTML("beforeend", clone);
        el.insertAdjacentHTML("beforeend", clone);

        setInterval(function () {
          firstElement.style.marginLeft = `-${i}px`;
          if (i > firstElement.clientWidth) {
            i = 0;
          }
          i += speed;
        }, 0);
      });
    }
  }

  textareaResize() {
    /**
     * Textarea
     */
    // expand height textarea
    function autoExpand(field) {
      // Reset field height
      field.style.height = "inherit";

      // Get the computed styles for the element
      const computed = window.getComputedStyle(field);

      // Calculate the height
      const height =
        Number.parseInt(computed.getPropertyValue("border-top-width"), 10) +
        field.scrollHeight +
        Number.parseInt(computed.getPropertyValue("border-bottom-width"), 10);

      field.style.height = `${height}px`;
    }

    document.addEventListener("input", (event) => {
      if (event.target.tagName.toLowerCase() !== "textarea") return;
      autoExpand(event.target);
    });
  }

  async contact() {
    const forms = document.querySelectorAll(".form-contact");

    if (forms) {
      forms.forEach(async (form) => {
        let key = "";
        window.addEventListener("load", () => {
          document
            .querySelector("altcha-widget")
            .addEventListener("statechange", (ev) => {
              if (ev.detail.state === "verified") {
                key = ev.detail.payload;
              }
            });
        });

        form.addEventListener("submit", async (e) => {
          e.preventDefault();
          const msgContainer = form.querySelector(".msg-form");
          const oldMsg = form.querySelectorAll(".msg-form .msg");
          if (oldMsg) {
            oldMsg.forEach((m) => m.remove());
          }

          const formData = new FormData();
          formData.append("nom", e.target.nom.value);
          formData.append("email", e.target.email.value);
          formData.append("msg", e.target.msg.value);
          formData.append("captcha", key);

          form.classList.add("loaded");

          try {
            const response = await fetch(url_contact, {
              method: "POST",
              body: formData,
            });

            const output = await response.json();

            form.classList.remove("loaded");

            if (output.status == "success") {
              form.reset();
            }

            const msgEl = document.createElement("div");
            msgEl.role = "status";
            msgEl.ariaLive = "polite";
            msgEl.ariaAtomic = "true";
            msgEl.innerHTML = `<p>${output.msg}</p>`;
            msgEl.classList.add("msg");

            msgContainer.prepend(msgEl);
          } catch (e) {
            console.error(e);
            form.classList.remove("loaded");

            const msgEl = document.createElement("div");
            msgEl.classList.add("msg");
            msgEl.innerHTML =
              "Une erreur s'est produite. Merci de réessayer ultérieurement.";
            msgContainer.prepend(msgEl);
          }

          return false;
        });
      });
    }
  }

  inViewport() {
    // In viewport
    inView.offset({
      bottom: window.innerHeight * 0.1,
    });
    inView(".js-reveal:not(.is-visible)")
      .on("enter", function (elt) {
        elt.dispatchEvent(new Event("inview"));
        elt.classList.add("is-visible");
        elt.classList.remove("not-visible");

        const video = elt.querySelector("video");

        if (
          (elt.tagName == "VIDEO" && elt.dataset.autoplay == "true") ||
          (video && video.dataset.autoplay == "true")
        ) {
          const v = video || elt;
          const playPromise = v.play();
          if (playPromise !== null) {
            playPromise.catch(() => {
              v.play();
            });
          }
        }
      })
      .on("exit", function (elt) {
        const video = elt.querySelector("video");
        if (
          (elt.tagName == "VIDEO" && elt.dataset.autoplay != "") ||
          (video && video.dataset.autoplay != "")
        ) {
          const v = video || elt;
          v.pause();
        }

        elt.dispatchEvent(new Event("outview"));
        elt.classList.add("not-visible");
      });
  }
}

window.addEventListener("DOMContentLoaded", () => {
  new App();
});
